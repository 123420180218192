import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import ExternalLink from "../static/svg/icon-external-link.inline.svg"
import Call from "../static/svg/icon-call.inline.svg"

function InfoEn(  ) {

  const { site } = useStaticQuery(graphql`
  query SiteAddressQueryEn {
    site {
      siteMetadata {
        postalcode,
        addresse,
        tel,
        kpostalcode,
        kaddresse,
        ktel
      }
    }
  }
`);

  return (

    <>

      <h2 className="heading-b mt-7 mx-1">
        Our Location
      </h2>

      <p>
        We are located at Nishiogikubo, Suginami-ku.
      </p>



      <p>
        We provide our service at Nishiogi Osteopathy Center.</p>
        <p>5 minutes from Nishiogikubo station&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line</p>
      <p><span className="font-semibold">Address:&nbsp;</span>{site.siteMetadata.addresse}</p>
      <div className="sm:pointer-events-none">
      <a href={`tel:${site.siteMetadata.tel}`.replace(/\s+/g, "")}>
        <p className="text-darkteal text-xl underline sm:no-underline mb-0 sm:m-2">
          <Call className="w-6 h-6 mb-0.5 inline pb-0.5 ml-1" />
          <span className="font-bold">{site.siteMetadata.tel}</span>
        </p>
      </a>
      </div>
      <p className="mt-0 text-sm sm:hidden">(Tap to call)</p>
      <p>
        <span className="font-semibold">Open：</span>Monday, Tuesday, Thursday and the third Saturday</p>
        <p className="pl-4">
        We&apos;re open from 10:00 to 18:00.<br />
        Last entry is at 16:30.  Please let us know if you are not able to come before 16:30, there are days you can visit us after 16:30.<br />
      </p>
      <p className="mb-0">
        <span className="font-semibold">Price：</span></p>
        <p className="pl-4 mt-0">Initial Consultation – &yen;8,800<br />Follow-Up Appointments – &yen;6,600<br />
        </p>
        <p className="mb-0">
        <span className="font-semibold">Session length：</span></p>
        <p className="mt-0 pl-4">About 90 minitues for the first session<br />About 60 minutes for the follow-up session
      </p>
      <p className="mt-6 text-lg underline text-blue-800 hover-hover:hover:text-blue-900">
        <a href="https://www.google.com/maps/place/Penguindo+osteopathy/@35.7008897,139.6001098,16z/data=!4m5!3m4!1s0x6018efb916a1ec21:0x130ac75b617c9130!8m2!3d35.7008897!4d139.6001098?hl=en" target="_blank" rel="noreferrer" >GoogleMap
          <ExternalLink className="h-5 w-5 align-middle inline pb-0.5 ml-1" />
        </a></p>
      <p className="mb-0">
        5 minutes from Nishiogikubo sta.&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line
      </p>
      <div className="mb-6 responsive-map">
        <StaticImage
          src="../images/NishiogiMapEn.jpg"
          alt="A map to Penguin-dou osteopathy from Nishiogikubo south-exit"
          placeholder="tracedSVG"
          layout="constrained"
          width={800}
        />
      </div>



     

    </>
  );
}



export default InfoEn;