import React from "react";
import { Link } from "gatsby";

import InformationCircleOutline from "../static/svg/icon-information-circle-outline.inline.svg";
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function InfoLinkEn(  ) {



  return (

    <div className="bg-pink-50 mt-1 mb-4 text-center pt-2 pb-4 ">

      <p className=""><InformationCircleOutline className="h-6 w-6 inline-block mr-0.5 align-bottom" />We are located in Nishiogikubo, Suginami-ku.</p>

      <div className="text-center block">
        <div className=" inline-block text-left">
          <div className="mb-1.5">

            <Link to='/en/nishiogi/' className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900">

              <ChevronDoubleRight className="h-4 w-4 align-middle inline  text-lx pb-0.5" />
              Information

            </Link>
          </div>

        </div>
      </div>


    </div>


  );
}



export default InfoLinkEn;