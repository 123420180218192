import PropTypes from "prop-types";
import React from "react";
import {  useStaticQuery,graphql } from "gatsby";

import Header from "./header"
import HeaderEn from "./headerEn"
import Menu from "./menu"
import SubMenu from"./subMenu"
import Footer from "./footer"
import FooterEn from "./footerEn"

function Layout ({ children, lang, pathTop }){

  const data = useStaticQuery(graphql`
  query layoutQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

  const linkToTopJ =  pathTop || '/';

  const langlink =  ( lang === `ja` )
                    ? '/en/' 
                    : linkToTopJ ;

  return (
    <div className="w-full flex flex-col min-h-screen font-body text-gray-900 tracking-wide leading-8 bg-c-base select-none">
      <main 
      className="container flex-1 xs:px-4 w-full max-w-screen-2xl mx-auto md:px-8 ">
        { lang === `ja` 
                    ? <Header langlink='/en/' tel={data.site.siteMetadata.tel} />
                    : <HeaderEn langlink='/' tel={data.site.siteMetadata.tel} />
        }
        { lang === `ja`
        ? <Menu lang={lang} langlink={langlink} pathTop={linkToTopJ} notShow />
        : <Menu lang={lang} langlink={langlink} />
      }
        <SubMenu lang={lang} langlink={langlink}/>
 
        {/* BODY */}
        {children}
        { lang === `ja` 
                    ? <Footer langlink='/en/' />
                    : <FooterEn langlink='/' />
        }
      
      </main>
    </div>


  );
}

Layout.defaultProps = {
  lang: `ja`,
  pathTop: null,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
  pathTop: PropTypes.string,
};

export default Layout;