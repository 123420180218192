import React from "react";
import {  graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ChevronDoubleRight from "../../static/svg/icon-chevron-double-right.inline.svg";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import InfoEn from "../../components/infoEn";
import InfoLinkEn from "../../components/infolinkEn";
import HeroImg from "../../components/heroImg";
import CtaEn from "../../components/ctaEn";


function IndexPage({ location }) {
  const {pathname} = location;

  return (
    <Layout lang="en" >

      <SEO lang="en" pageurl={pathname} />

      <section>

      <div className="relative">
        <div className="ml-auto my-auto">
        <HeroImg lang='en'
           />
        </div>
        <div className="hidden absolute top-0 bottom-0 right-0 left-0 m-auto md:flex flex-col justify-center text-center hidden-ie text-gray-800">
            <div className="border-2 border-yellow-500 inline-block  mx-auto bg-white">
            <p className="md:mt-2.5 text-xs 
            md:text-2xl 
            lg:text-3xl
            xl:text-4xl
            2xl:text-5xl" >
              <span className="font-bold md:tracking-widest ">Hands On Care For The Whole Family</span></p>
            <p className=" text-xs xs:text-base lg:text-2xl">Your Osteopath in Nishiogikubo, Suginami-ku</p>


            </div>
        </div>
      </div>

      <h1 className="heading-b mt-3 mx-1">
        Are you looking for relief from pain or discomfort?
      </h1>

      <p>We are providing health care and pain management through osteopathy in Nishiogikubo Suginami-ku.</p>

      <div className="overflow-hidden" >
      <StaticImage
          src="../../images/seitai-session1.jpg"
          alt="osteopathy session"
          placeholder="blurred"
          layout="constrained"
          width={150}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />
<p>
        Osteopathy is a drug-free, non-invasive manual therapy that aims to improve health across all body systems by manipulating and strengthening the musculoskeletal framework.  And the osteopathic session also aims positively affect the body’s nervous, circulatory, and lymphatic systems.
        </p>
        </div>

    

        <h2 className="mt-7 heading-b mx-1">
        Is osteopathy for you?
      </h2>


              <p>Osteopathy can help keep your whole body in alignment to reduce and help prevent the progression of problems.</p>
              <div className="overflow-hidden" >
        <StaticImage
          src="../../images/backpain.png"
          alt="back pain"
          placeholder="blurred"
          layout="constrained"
          width={150}
          className="max-w-2/5 float-right mr-5 my-3 ml-2"
        />
              <p>
Whether you have acute pain, ongoing niggles or are generally feeling worn down, osteopathy is an effective way to help relieve your pain and manage your condition.</p>

<p>
Osteopathy can help with many conditions such as neck and back pain, headaches, repetitive strain and overuse injuries, postural problems and so on.
</p>
</div>

<h2 className="heading-b mt-7 mx-1 ">
        About us
      </h2>
      <Link to='/en/profile/' className="mt-2">
            <p className="mb-0  text-lg underline text-c-main hover-hover:hover:text-c-acc">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline  text-lx pb-0.5" />
              Tomoko Hashimoto (osteopath)
              </p>
              <StaticImage
              src="../../images/profile.jpg"
              alt="Penguin-dou osteopath"
              placeholder="blurred"
              layout="constrained"
              width={150}
              className=""
            />

            </Link>
            
            <InfoLinkEn />
            <div className="mx-1">
            <InfoEn />
            </div>
            <CtaEn />
      </section>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`